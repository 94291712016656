

.form-field-label {
  font-family: var(--font-family);
  color: var(--icon-color);
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
