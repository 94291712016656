.tab-title {
  position: relative;

  &__error {
    position: absolute;
    top: -3px;
    right: -6px;
    height: 6px;
    width: 6px;
    background-color: var(--icon-color-danger);
    border-radius: 3px;
  }
}
