.resource-limitation {
    font-family: var(--font-family);
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--icon-color);
    gap: 3px;
    position: absolute;
    right: 1px;
    top: 0px;


    &__value {
        height: 18px !important;
        width: auto;
        border-color: transparent;
        background: none;
        outline: none;
        padding: 0;
        font-family: var(--font-family);
        color: var(--icon-color);
        font-size: 12px;
        display: block;

        &.is-invalid {
            border-radius: 6px;
            border: 1px solid #dc3545;
        }
    }


    .button-text {
        font-family: var(--font-family);
    }

}
