$primary: #fdd854;
$secondary: #2a3042;
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import './icons';
@import './table';
@import './layout';

:root {
  --primary: #fdd854;
  --secondary: #2a3042;

  --font-family: 'Roboto';
  --font-disabled: #a7b8c7;
  --font-color: #1e2a33;

  --secondary-bg-hover: #e6f7ff;

  --icon-color: #607487;

  --tooltip-background: #ffffff;
  --controls-bar-height: 35px;

  --input-height-base: 36px;
  --input-height-in-tab: 32px;
  --input-disabled-border: #d9d9d9;
  --input-disabled-text: #959595;
  --input-disabled-bg: #fbfbfb;
  --input-form-height: 36px;

  --alerts-danger: #f5222d;
  --icon-color-danger: #f5222d;

  --box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

}

body {
  font-family: 'Poppins', sans-serif;
  background: #f8f8fb;
  color: var(--secondary);
  overflow: hidden;
}

.btn-outline-primary {
  color: inherit !important;
}

.btn {
  font-size: 0.9rem;
}

.btn-primary {
  color: var(--secondary);
}

.cursor-pointer {
  cursor: pointer;
}

.form-error-message {
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--alerts-danger);
  position: absolute;
}
