.power-calculator-result-table {
  height: 70vh;
  position: relative;
  display: flex;
  flex-direction: column;

  &__content {
    overflow-y: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  ul {
    margin: 1rem;
  }
}
