.header__wrapper {
  height: 70px;
  width: 100vw;
  background: linear-gradient(90deg, var(--secondary) 50%, white 50%);
  display: flex;
  align-items: center;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.header__left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 74%;
  height: 100%;
  padding-right: 15px;
  background: var(--secondary);
}
.header__right {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 26%;
  padding-left: 15px;
  height: 100%;
  background: white;
  .dropdown {
    flex: 1 1 auto;
  }
  .dropdown-menu {
    max-width: 300px;
    inset: unset !important;
    right: 0px !important;
    top: 0px !important;
  }
}

.header__nav {
  font-size: 0.85rem;
  display: flex;
  gap: 30px;
  align-items: center;
  a {
    color: white;
    text-decoration: none;
    display: flex;
    gap: 10px;
    align-items: center;
    &.header__link_active {
      color: var(--primary);
    }
    i {
      color: white;
    }
  }
}

.letter__circle {
  border-radius: 50%;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
}
.header__right_name {
  font-size: 13px;
  line-height: 20px;
  color: #555b6d;
}
.header__right_role {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #adb5bd;
}

.header__right_name_wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
