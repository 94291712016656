.power-calculator-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.power-calculator-delete__title,
.power-calculator-delete__subtitle {
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: #22252c;
}
