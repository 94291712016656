.power-calculator-form {
  height: 70vh;
  display: flex;
  flex-direction: column;

  &__input-params {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    padding: 0px 5px;
  }
}
