

.field-with-label-and-value {
    font-family: var(--font-family);

    &__value {
        border-color: transparent;
        background: none;
        outline: none;
        padding: 0;
        font-family: var(--font-family);
        color: var(--icon-color);
        font-size: 12px;
        display: block;

        &.error {
            &::placeholder {
                color: red;
            }
        }
    }

    &__label {
        display: flex;
        align-items: center;
    }

    &__field {
        flex: 1;
    }

}
