.sidebar {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 2;
  max-width: 100vw;
  position: absolute;
  right: 0;
  border-top: 1px solid #d9d9d9;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.03);
  height: 100%;


  &__body {
    height: 100%;
    padding-right: 10px;
    row-gap: 5px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
