.login__wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8fb;
}
.login__form {
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding-bottom: 43px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 8px;
}
.login__form_header {
  background: var(--secondary);
  color: white;
  padding: 27px 25px 34px 31px;
  display: flex;
  border-radius: 8px 8px 0px 0px;
}
.login__form_title_wrapper {
  margin-top: 25px;
}
.login__form__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.login__form__subtitle {
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 20px;
}
.login__form_body {
  padding: 0 28px;
  padding-top: 20px;
}
.login__form_inner {
  display: flex;
  flex-direction: column;
}
.login__input {
  margin-top: 10px;
}
.login__input::placeholder {
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 20px;
  color: var(--secondary);
}
.login__btn {
  margin-top: 30px;
}
