.radio-field {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;

  &__box {
    border: 1px solid var(--font-disabled);
    height: 32px;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    color: var(--secondary);
    cursor: pointer;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    margin-right: 4px;
    border-radius: 7px;

    &:first-child {
      margin-right: 10px;
    }

    &--left {
      border-right: 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &:has(> input.radio-field__input:checked) {
      background: var(--primary);
    }

    &:has(> input.radio-field__input.asCheckbox:checked) {
      background: rgba(255, 0, 0, 0.216);
      color: rgba(0, 0, 0, 0.593);
    }
  }

  &__input {
    display: none;
  }
}
