.react-bootstrap-table {
  font-size: 0.85rem;
}
.page-link {
  color: inherit;
}

.thead-light {
  border-bottom: 2px solid #eff2f7;
  border-top: 1px solid #eff2f7;
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 20px;
  color: #495057;
  tr {
    th {
      border-bottom: none;
    }
  }
}

.page-item.active .page-link {
  color: var(--secondary);
}

.pagination {
  justify-content: end;
}

.react-bootstrap-table-pagination {
  font-size: 0.85rem;
  div:first-of-type {
    display: flex;
    align-items: center;
  }
}

.dropdown__btn {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}
