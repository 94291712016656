.layout__wrapper {
  min-height: 100vh;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page__wrapper {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  padding-bottom: 34px;
}
