.licenses__table_row {
  height: 60px;
}
.licenses__table_wrapper {
  .thead-light {
    background: #f8f9fa;
  }
}

.status__wrapper {
  width: 14px;
  height: 14px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .status {
    width: 6px;
    height: 6px;

    border-radius: 50%;
    &__expired {
      background: #f2f2f5;
    }
    &__active {
      background: #34c38f;
    }
  }
}

.status__tr {
  text-align: center;
}
