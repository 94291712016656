.input-parameter {
  display: flex;
  align-items: flex-end;
  gap: 10px;

  &__label {
    width: 80%;
  }

  &__controls {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 23px 0 6px 6px;

    .button__icon {
      font-size: 22px;
      cursor: pointer;

      &--trash {
        color: red;
      }

      &--plus {
        color: green;
      }
    }
  }
}
