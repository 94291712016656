.breadcrumbs__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #495057;
}

.breadcrumbs__first_item {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #495057;
}
.breadcrumbs__second_item {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #74788d;
}
