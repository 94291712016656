.tabs {
  display: flex;

  .nav-link {
    height: 52px;
    padding: 17px 8px;
    border: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    border-bottom: 2px solid transparent;
    font-weight: 500;



    &.active {
      border: none;
      border-bottom: 2px solid var(--primary) !important;
      color: var(--primary);
    }
  }
}
