.microservice-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;


  &__item {
    position: relative;
  }

 
}
