.power-calculator {
  height: 50vh;
  flex: 1;
  display: grid;
  grid-template-columns: 40% 1fr;
  position: relative;
  gap: 20px;

  &__skeleton {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
