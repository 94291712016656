.input-params-with-def-value {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 70px;
  }

  &__field {
    flex: 1;
  }

}
