.users__modal_delete_title,
.users__modal_delete_login {
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #22252c;
}
// .users__modal_delete_login {
//   font-size: 20px;
//   font-weight: 500;
// }
