.licenses__delete_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.licenses__delete_title,
.licenses__delete_company {
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: #22252c;
}

.licenses__delete_date {
  margin-top: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #adb5bd;
}
