.modal__logo_header {
  position: relative;
  color: white;
  background: var(--secondary);
  display: flex;
  height: 100px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 8px 0 0;
}

// .modal-content {
//   border-radius: 30px 8px 8px 8px !important;
// }

.modal__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
}

.modal__close_icon {
  position: absolute;
  top: -15px;
  cursor: pointer;
  right: -13px;
  border-radius: 50%;
  width: 32px;
  color: black;
  height: 32px;
  background: white;
  border: 2px solid var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
