.footer__wrapper {
  background: #f2f2f5;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  height: 44px;
  color: #74788d;
  background: #f2f2f5;
  display: flex;
  align-items: center;
}
