.licenses__filter_group {
  font-size: 0.9rem;
  .filter__title {
    font-weight: 700;
    color: #495057;
  }

  .filter__select {
    margin-top: 5px;
    font-size: 0.85rem;
    width: 232px;
  }
}
