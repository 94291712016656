.rule-item {
  display: flex;
  gap: 5px;
  justify-content: stretch;

  &__controls {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 23px 0 6px 6px;

    .button__icon {
      font-size: 22px;
      cursor: pointer;

      &--trash {
        color: red;
      }

      &--plus {
        color: green;
      }
    }
  }
}
