.table__dropdown_list {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 8px;
  border: none !important;
  padding: 20px !important;
  padding-bottom: 10px !important;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 20px;
  color: #495057;
  button {
    &:active {
      color: inherit;
      background: transparent;
      text-decoration: none;
    }
    &:hover {
      background: transparent;
    }
  }
}
.table__dropdown_item_edit {
  position: relative;
  padding: 10px !important;

  margin-bottom: 3px;
  &:after {
    content: '';
    width: 100%;
    background: #eff2f7;
    height: 1px;
    position: absolute;
    bottom: -3px;
    left: 0;
  }
}
.table__dropdown_item_delete {
  padding: 10px !important;
  color: #7f8bfc !important;
}
