.setting-constructor {
  font-family: var(--font-family);

  &__form {
    display: flex;
    flex-direction: column;
    padding: 8px;
    height: 100%;
    overflow: auto;
    flex: 1;

  }

  &__body {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  &__content {
    flex: 1;
    padding: 5px;
  }

  &__controls {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 23px 0 6px 6px;

    &-button {
      width: 100%;
    }
  }

  ul.nav-tabs .nav-item .nav-link {
    border: none;
  }

}
