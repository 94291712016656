.constructor {
  .branching {}

  .node {
    margin: 25px;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    position: relative;
    background-color: #fdd854;
    border: solid var(--secondary);
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    line-height: 1.2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &__name {
      position: absolute;
      font-weight: 400;
      bottom: -26px;
      left: 50%;
      transform: translate(-50%, -2px);
      white-space: nowrap;
    }
  }

  .handle {
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      height: 15px;
      width: 30px;
      transform: translate(-50%, 0);
      background: white;
      z-index: 1000;
      line-height: 1;
      border-radius: 4px;
      color: #fff;
      font-size: 9px;
      border: 2px solid #222138;
    }
  }

  .custom-handle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
  }
}
