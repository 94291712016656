.power-calculator-total-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;

  &__indicators {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }


  &__indicator {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
