.tools {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  // top: 0;
  // left: 50%;
  // transform: translate(-50%, 0);
  font-size: 12px;


  // -webkit-box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);
  // -moz-box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);
  // box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);

  .dndnode {
    cursor: grab;
    margin: 0px !important;

    &:before {
      opacity: 0;
    }
  }
}
