.virtual-machine-cost {
  padding: 0 3px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__params {
    display: flex;
    gap: 10px;
  }

  &__parameter {}
}
