.info-label {

  &:hover {
    .form-field-label__icon {
      color: yellow;
    }

    cursor: pointer;
  }

}
