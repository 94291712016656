.users__modal_password_input {
  width: 60% !important;
}
.users__modal_password_btn {
  width: 40% !important;
  margin-left: 15px;
  color: inherit !important;
}
.users__modal {
  width: 600px !important;
  max-width: 600px !important;
  .modal-content {
    border-radius: 12px;
    border: none;
  }
}

.users__modal_submit_btn {
  width: 100%;
  max-width: 144px;
  margin-left: 20px;
}
.users__modal_btns_wrapper {
  button {
    color: inherit !important;
  }
}
