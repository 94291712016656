.input-params {
  top: 0;
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  font-family: var(--font-family);
  background: #fcfcfc;
  position: absolute;
  max-width: 400px;
  min-width: 28px;
  height: max-content;
  padding-left: 12px;
  font-size: 16px;


  -webkit-box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    position: relative;
    padding: 2px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    max-height: 45vh;
  }


  &__toggle_visibility {
    top: 7px;
    left: 6px;
    position: absolute;
  }
}
