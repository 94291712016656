.constructor {
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;

  .react-flow {
    position: absolute !important;
  }

  .react-flow__node:hover {
    z-index: 1 !important;
  }

  .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }

  .tab-content {
    padding-top: 10px;
  }

  &__buttons {
    display: flex;
    gap: 5px;
    justify-content: stretch;

    button {
      width: 100%;
    }
  }
}
