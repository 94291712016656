input,
select,
textarea,
button,
.modal-body {
  font-size: 0.85rem !important;
}

.input__title {
  font-weight: 600;
  font-size: 0.85rem;
  color: #495057;
  margin-bottom: 4px;
}

.resize-none {
  resize: none;
}

.page__btn {
  font-size: 0.85rem;
  color: #2a3042;
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.container-fluid {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.modal__paddings {
  padding: 58px 75px;
}

.modal__delete_btns_wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  button {
    width: 103px;
    font-weight: 400;
    font-size: 0.85rem;
    text-align: center;

    color: #2a3042;
  }
}

.modal__confirm_title {
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: #22252c;
}

.modal__confirm_content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #adb5bd;
}
